





import Vue from "vue";
import Component from "vue-class-component";
import { IDeviceTest } from "./CheckDevice/IDeviceTest";
import CameraTest from "./CheckDevice/camera-test";
import MicTest from "./CheckDevice/mic-test";
import AgoraTest from "./CheckDevice/agora-test";
import { createUUID } from "@/utilities/uid-utils";
import BuyerDeviceChecks from "@/components/buyer-device-checks/BuyerDeviceChecks.vue";
@Component({
  components: {
    BuyerDeviceChecks
  }
})
export default class CheckDevice extends Vue {
}
