


















import BaseVue from "../utilities/base-vue";
import { Component } from "vue-property-decorator";
import RtcStatsPanel from "../components/rtc-stats-panel.vue";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import ScreenSaver from "../components/ScreenSaver.vue";
import NetworkCheck from "@/components/network-check/NetworkCheck.vue";
import CustomAlert from "@/components/CustomAlert.vue";

@Component({
  name: "Camera",
  components: {
    RtcStatsPanel,
    ScreenSaver,
    NetworkCheck,
  },
  props: {
    booth: {
      type: String,
      default: "milano1",
    },
    cameraId: {
      type: String,
      default: "1",
    },
  },
})
export default class Camera extends BaseVue {
  _publishing = false;
  private _uid: number;
  media: MediaStream;
  protected me: Camera;
  unpublishAndLeaveTimeout: number;
  unpublishAndLeavePromise: Promise<void> = Promise.resolve();
  cameraStream: MediaStream;
  recordingSession: string;
  recording: boolean = false;
  count: number = 0;
  stats: boolean = false;

  /**
   *
   */
  constructor() {
    super();
    console.log("creating camera component " + this.$props.cameraId);
    this._uid = +this.$props.cameraId;
    this.$logSend.addToContext("user", this.$gooseapi.getUsername());
    this.$logSend.addToContext("booth", this.$props.booth);
    this.$logSend.addToContext("uid", this._uid);
    this.$logSend.enable();

    this.$signalr.onChangeCameraRequest(this, this.onChangeCameraRequest);

    this.$signalr.onJoinedBoothControl(this, this.onJoinedBoothControl);

    this.$signalr.onRefreshRequest((requestingUser: string) => {
      console.log(`${requestingUser} requested a page refresh`);
      location.reload();
    });
    document.addEventListener("visibilitychange", async () => this.onVisibilityChange());

    this.$signalr.onLeftBoothControl(this, () => {});
    this.$signalr.onCallClosed(this, () => {});
  }

  async onVisibilityChange() {
    if (document.visibilityState === "hidden") {
      console.log("onvisibility change hidden");
      try {
        await this.stopRecording();
      } catch (error) {
        //
      }
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach((t) => {
          try {
            t.stop();
          } catch (error) {
            //
          }
        });
        this.cameraStream = null;
      }
    } else {
      console.log("onvisibility change visible");
      await this.initCameraAndVideo();
    }
  }
  async stopRecording() {
    await this.$instorerecording.stopRecordingSession();
    this.recording = false;
  }

  async onJoinedBoothControl(_, booth, uid) {
    console.debug(`User ${uid} joined booth control channel`);
    if (uid === 1111) {
      if (this.unpublishAndLeaveTimeout) {
        console.debug(`User is seller and previous unpublish and leave timeout is active`);
        window.clearTimeout(this.unpublishAndLeaveTimeout);
        this.unpublishAndLeaveTimeout = null;
      }
    }
  }

  async onRemoteUserLeft(sender: Camera, user: IAgoraRTCRemoteUser) {
    console.debug(`user ${user.uid} left booth control channel`);
    if (user.uid === 1111) {
      if (this.unpublishAndLeaveTimeout) {
        console.debug("a previous unpublish timeout is present, resetting it");
        window.clearTimeout(this.unpublishAndLeaveTimeout);
        this.unpublishAndLeaveTimeout = null;
      }
      const timeoutMin = 10;
      if (this._publishing) {
        console.debug(`Camera is still publishing, setting unpublish and leave timeout of ${timeoutMin} minutes`);
        this.unpublishAndLeaveTimeout = window.setTimeout(async () => {
          console.debug(`seller did not return in ${timeoutMin} minutes, leaving channel`);
          this.unpublishAndLeavePromise = this.unpublishAndLeave();
          await this.unpublishAndLeavePromise;
        }, timeoutMin * 60 * 1000);
      }
    }
  }

  async onChangeCameraRequest(cameraId: number, mode: "Online" | "InStore", session: string, timestamp: number, forceCameraReset: boolean = false, channelSuffix: string) {
    console.log(`onChangeCameraRequest ${cameraId}, ${mode}, ${session}, ${timestamp}, ${forceCameraReset}`);
    if (session) this.$logSend.addToContext("session", session);
    else this.$logSend.removeFromContext("session");

    switch (mode) {
      case "Online":
        await this.onChangeCameraRequestOnline(cameraId, channelSuffix);
        break;
      case "InStore":
        await this.onChangeCameraRequestInStore(cameraId, session, timestamp, forceCameraReset);
        break;
      default:
        break;
    }
  }

  async onChangeCameraRequestInStore(cameraId: number, session: string, timestamp: number, forceCameraReset: boolean = false) {
    console.log(`onChangeCameraRequest in store ${cameraId}`);
    if (this.unpublishAndLeaveTimeout) {
      window.clearTimeout(this.unpublishAndLeaveTimeout);
      this.unpublishAndLeaveTimeout = null;
    }

    await this.switchRecordingState(cameraId, session, timestamp, forceCameraReset);

    if (cameraId === -1) {
      this.$logSend.removeFromContext("session");
    }

    if (cameraId === 0) {
      await this.joinBoothControl(this._uid, this.$props.booth, "camera");
    }
  }

  async switchRecordingState(cameraId: number, session: string, timestamp: number, forceCameraReset: boolean) {
    if (process.env.VUE_APP_FULL_SESSION_RECORDING_ENABLED != "true") {
      await this.$signalr.sendCameraRecording(cameraId, this.recordingSession, timestamp, this.$props.booth);
      return;
    }

    if (cameraId === this._uid) {
      if (this.recording) {
        if (forceCameraReset) {
          await this.$instorerecording.stopRecordingSession();
          this.recording = false;
        } else {
          await this.$signalr.sendCameraRecording(cameraId, this.recordingSession, timestamp, this.$props.booth);
          return;
        }
      }
      console.log("Seller has requested this camera to start recording");

      var { recordingSession } = await this.$instorerecording.startRecordingSession(this.cameraStream, cameraId, session || this.recordingSession, timestamp);
      this.recordingSession = recordingSession;
      await this.$signalr.sendCameraRecording(cameraId, this.recordingSession, timestamp, this.$props.booth);
      this.recording = true;
      console.log("Camera has started recording (may take a while to confirmation to be sent to seller)");
    } else if (this.recording) {
      await this.stopRecording();
    }
  }

  async onChangeCameraRequestOnline(cameraId: number, channelSuffix: string) {
    console.log(`onChangeCameraRequest online ${cameraId}`);
    if (this.unpublishAndLeaveTimeout) {
      window.clearTimeout(this.unpublishAndLeaveTimeout);
      this.unpublishAndLeaveTimeout = null;
    }
    if (cameraId === this._uid) {
      await this.unpublishAndLeavePromise;
      if (this._publishing) {
        console.debug("Camera is still publishing");
        await this.$signalr.sendCameraStillPublishing(this._uid, this.$props.booth);
      }
      await this.joinBoothChannel(this._uid, channelSuffix);
      await this.publish();
      this.recording = true;
    } else if (this._publishing) {
      await this.unpublishAndLeave();
      this.recording = false;
    }
  }

  async unpublishAndLeave() {
    console.debug("Unpublishing stream and leaving channel");
    await this.unpublish();
    await this.leaveBoothChannel();
  }

  async mounted() {
    console.log("mounting");
    await this.tryJoinBoothControlOrReload();
  }

  async tryJoinBoothControlOrReload(): Promise<boolean> {
    const pleaseWait = new CustomAlert({
      propsData: {
        title: "Please wait",
        body: "Initializing Sneakers Maker Control Station",
        showButton: false
      }
    });
    pleaseWait.show(this.$refs.container as Element);
    let retries = 0;
    do {
      try {
        await this.joinBoothControl(this._uid, this.$props.booth, "camera");
        await this.initCameraAndVideo();
        break;
      } catch (e) {
        console.warn(e);
        await new Promise<void>(resolve =>
          setTimeout(() => {
            resolve();
          }, Math.pow(3, ++retries) * 1000)
        );
        // alert("There was an error initializing the page, please refresh to retry");
      }
    } while (retries < 3);
    pleaseWait.hide();
    if (retries >= 3) {
      debugger;
      const customAlert = new CustomAlert({
        propsData: {
          title: "Error",
          body: "There was an error initializing the page, click ok to retry",
          showButton: true
        }
      });
      customAlert.show(this.$refs.container as Element, () => {
        location.reload();
      });
      return false;
    }
    return true;
  }

  async initCameraAndVideo() {
    var stream = await this.getCameraStream();

    const track = stream.getVideoTracks()[0];

    await this.getCameraTrack(track);
    document.querySelector("#local_stream").innerHTML = "";

    this.addPosterAttribute(
      "local_stream",
      "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Crect stroke-width='0' id='svg_1' height='100' width='100' y='0' x='0' stroke='%23000' fill='%23000000'/%3E%3C/g%3E%3C/svg%3E"
    );
    await this.playVideoTrackTo("local_stream");
  }

  async getCameraStream() {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        facingMode: "environment",
        width: { min: 1278, ideal: 1278 },
        height: { min: 720, ideal: 720 },
        frameRate: { min: 30, ideal: 30 },
        aspectRatio: { ideal: 1.7777778 },
      },
    });
    this.cameraStream = stream;
    return stream;
  }

  async publish(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    await this.publishTracks(this.localVideoTrack);
    this._publishing = true;
  }

  async unpublish(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    await this.unpublishTracks(this.localVideoTrack);
    this._publishing = false;
  }

  showStats() {
    if (this.stats) return;
    this.count = this.count + 1;
    setTimeout(() => {
      this.count = 0;
    }, 3000);
    this.stats = this.count >= 3;
  }
}
