






import jwtDecode from "jwt-decode";
import Vue from "vue";
import Component from "vue-class-component";
import { getCurrentAuthToken } from "./services/goose-apis";
import { startupHeartbeat, stopHeartbeat } from "./services/heartbeat.worker";

@Component({})
export default class App extends Vue {
  constructor() {
    super();
    this.$logSend.enable();
    console.log("LogSend enabled");
  }

  public get showEnvName(): boolean {
    return !this.isProduction && !this.isDevelopment;
  }

  get envName(): string {
    return process.env.VUE_APP_ENV || "development";
  }

  get isDevelopment(): boolean {
    return process.env.NODE_ENV !== "production" || this.envName === "development";
  }

  get isProduction(): boolean {
    return this.envName === "production";
  }

  get isTest(): boolean {
    return this.envName === "test";
  }

  get isStaging(): boolean {
    return this.envName == "staging";
  }

  async mounted() {
    console.log("Sneaker maker app is starting");

    // Storage check
    if ("storage" in navigator && "estimate" in navigator.storage) {
      navigator.storage.estimate().then(({ usage, quota }) => {
        usage = Math.floor(usage / 1024 / 1024);
        quota = Math.floor(quota / 1024 / 1024);
        console.log(`Sneaker maker app using ${usage} out of ${quota} Mbytes.`);
      });
    }

    let token = this.$gooseapi.getCurrentToken();
    if (token) {
      token = await this.$gooseapi.refreshToken();
      let jwt = jwtDecode(token);
      let locationDescription = jwt["booth-location-description"];
      console.log(`Got location description ${locationDescription}`);
      const role = jwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      let experienceDeviceId = "";
      if (role === "seller") {
        experienceDeviceId = "SMTablet";
      } else {
        switch (jwt["cameraId"]) {
          case "10001":
            experienceDeviceId = "CameraMarker";
            break;
          case "10002":
            experienceDeviceId = "CameraPunzonatrice";
            break;
          case "10003":
            experienceDeviceId = "CameraStampa";
            break;
          case "10004":
            experienceDeviceId = "CameraSmudge";
            break;
        }
      }

      let AppGUUID = "---";
      if (this.$jsBridge && this.$jsBridge.getDeviceId) {
        AppGUUID = this.$jsBridge.getDeviceId();
      }
      startupHeartbeat(this.HBIs.HBUrl, 1, locationDescription, "Sneaker Maker", experienceDeviceId, AppGUUID);
     
    }
    this.$signalr.onRecordingSessionUploadable(this, this.onRecordingSessionUploadable);
  }
  //
  // HB and AppGUUID useful quantities...
  HBIs = {
    HBUrl: process.env.VUE_APP_HEARTBEAT_ENDPOINT || (/localhost/.test(location.href) ? "https://localhost:5005/devicesstatus" : "https://goosewssdev.eu.ngrok.io/devicesstatus"),
    AppGUUID: "---",
    running: false,
    stdHBStartupTimer: 30000,
  };

  beforeDestroy() {
    stopHeartbeat();
  }

  onRecordingSessionUploadable(recordingSession: string) {
    console.log(`Received recording session uploadable event from signalr, setting session ${recordingSession} as uploadable`);
    this.$dbService.setSessionUploadable(recordingSession);
  }
}

