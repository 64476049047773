import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import Vue, { PluginFunction } from "vue";

declare module 'vue/types/vue' {
   interface Vue {
      $polaroid: PolaroidService;
   }
}

export default class PolaroidService extends Vue {
   public static install: PluginFunction<PolaroidServiceOptions> = (__instance, options) => {
      __instance.prototype.$polaroid = new PolaroidService(options);
   }
   uploadApiUrl: string;
   devicesApiUrl: string;

   constructor(options: PolaroidServiceOptions) {
      super();
      this.uploadApiUrl = options.uploadApiUrl;
      this.devicesApiUrl = options.devicesApiUrl;
   }

   async getPolaroid(recordingSession: string, customerAgoraToken: string): Promise<string> {
      let retries = 0;
      let pictureBlob: Blob = null;
      do {
         try {
            const response = await fetch(`${this.uploadApiUrl}/sessionphotos/for-customer/${recordingSession}`,
               {
                  headers: {
                     'Authorization': `Bearer ${customerAgoraToken}`
                  }
               })
            if (response.ok) {
               pictureBlob = await response.blob();
               break;
            }
            else {
               console.error('Could not get polaroid HTTP-' + response.status + ' ' + JSON.stringify(await response.text()));
            }
            retries++;
         } catch (error) {
            console.error('Could not get polaroid ' + JSON.stringify(error));
         }
         pictureBlob = null;
      } while (!pictureBlob && retries < 3);

      if (pictureBlob) {
         const pictureBlobUrl = URL.createObjectURL(pictureBlob);
         return pictureBlobUrl;
      }

      return null;
   }

   async sendPolaroid(recordingSession: string, booth: string, customerUrl: string, file: Blob, securityToken: string): Promise<boolean> {
      let uploadResult = false;
      let retries = 0;
      do {
         uploadResult = await this.uploadFile(recordingSession, file, securityToken);
         if (!uploadResult && ++retries < 3) await new Promise<void>(resolve => setTimeout(() => resolve(), retries * 500))
      } while (!uploadResult && retries < 3);
      if (!uploadResult) return false;

      let notifyResult = false;
      retries = 0;
      do {
         notifyResult = await this.notifyPolaroidReady(recordingSession, booth, customerUrl);
         if (!notifyResult && ++retries < 3) await new Promise<void>(resolve => setTimeout(() => resolve(), retries * 500))
      } while (!notifyResult && retries < 3);
      if (!notifyResult) return false;

      return true;
   }

   async notifyPolaroidReady(recordingSession: string, booth: string, customerUrl: string): Promise<boolean> {
      const urlNotify = `${this.devicesApiUrl}/sessionphotonotifications`;
      let notifyResponse: Response;
      try {
         notifyResponse = await fetch(urlNotify, {
            method: 'POST',
            headers: {
               "content-type": "application/json"
            },
            body: JSON.stringify({
               recordingSession,
               booth,
               customerUrl
            })
         })
      } catch (error) {
         console.error(`An error occurred posting to ${urlNotify}: ${error}`);
         return false;
      }

      if (!notifyResponse.ok) {
         console.error(`An error occurred posting to ${urlNotify}: HTTP-${notifyResponse.status} ${await notifyResponse.text()}`);
         return false;
      }

      return true;
   }

   async uploadFile(recordingSession: string, file: Blob, securityToken: string) {
      const formData = new FormData();
      formData.append("recordingSession", recordingSession);
      formData.append("appId", 'Sneaker maker');
      formData.append("file", file);

      const urlUpload = `${this.uploadApiUrl}/sessionphotos`;
      let uploadResponse: Response;
      try {
         uploadResponse = await fetch(urlUpload, {
            method: "POST",
            body: formData,
            headers: {
               'Authorization': 'Bearer ' + securityToken
            }
         });
      } catch (error) {
         console.error(`An error occurred posting to ${urlUpload}: ${error}`);
         return false;
      }

      if (!uploadResponse.ok) {
         console.error(`An error occurred posting to ${urlUpload}: HTTP-${uploadResponse.status} ${await uploadResponse.text()}`);
         return false;
      }

      return true;
   }

}

export class PolaroidServiceOptions {
   constructor(public uploadApiUrl: string, public devicesApiUrl: string) { }
}