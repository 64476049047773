import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./services/store";
import SignalR, { ForeverPolicy } from "./services/signalr";
import LogSend, { LogSendOptionsBuilder } from "./services/logSend";
import SignalRSink from "./services/logSend/signalr-sink";
import { AgoraRTCClientOptions, AgoraVue } from "./agora-rtc";
import GooseAPIClient, { getCurrentAuthToken } from "./services/goose-apis";
import vSelect from "vue-select";
import vOutsideEvents from "vue-outside-events";
import CamerasTracker from "./services/cameras-tracker";
import { InStoreRecordingServicePlugin } from "./services/instore-recording-service";
import IndexedDbService from "./services/indexed-db-service";
import { messages, defaultLocale } from "@/i18n";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import BootstrapVue from 'bootstrap-vue';

import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-vue/dist/bootstrap-vue.css';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured"
    ],
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });

}

Vue.config.productionTip = false;

Vue.component("v-select", vSelect);
Vue.use(vOutsideEvents);
Vue.use(BootstrapVue);

Vue.use(SignalR, {
  url: process.env.NODE_ENV != "development" ? getSignalRServer() : getDevelopmentSignalRServer(),
  automaticRetryCallback: new ForeverPolicy()
});

Vue.use(
  LogSend,
  new LogSendOptionsBuilder()
    .withAppName("GoldenGoose Sneaker Maker")
    .withSinks(new SignalRSink(Vue.prototype.$signalr.connection, "SendLog"))
);

Vue.use(AgoraVue, new AgoraRTCClientOptions(process.env.NODE_ENV === "production" ? "9c081bf9056f4ffca178282bee3c40b7" : "a663dae2ddd642e084981b9f49c9dc8e"));
Vue.use(IndexedDbService);
Vue.use(InStoreRecordingServicePlugin);
Vue.use(VueI18n);

Vue.use(VueJsBridge);

Vue.use(GooseAPIClient, {
  baseUrl: process.env.NODE_ENV !== "development" ? getApiBaseUrl() : getDevelopmentApiBaseUrl()
});

Vue.use(UploadService, {
  baseUrl: process.env.NODE_ENV !== "development" ? getUploadApiBaseUrl() : getUploadDevelopmentApiBaseUrl()
});

const uploadApiUrl = getUploadApiBaseUrl();
const apiBaseUrl = getApiBaseUrl();
Vue.use(PolaroidService, new PolaroidServiceOptions(uploadApiUrl, apiBaseUrl));

Vue.use(CamerasTracker);

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});

const app = new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");

import { start } from './services/file.worker';
import UploadService from "./services/upload-service";
import jwtDecode from "jwt-decode";
import { appReloadRecovery } from "./services/app-reload-recover-file-merger.worker";
import PolaroidService, { PolaroidServiceOptions } from "./services/polaroid-service";
import VueJsBridge from "./services/js-bridge/js-bridge";

const authToken = getCurrentAuthToken();
if (authToken) {
  console.log('Starting web workers');
  start(process.env.NODE_ENV, process.env.NODE_ENV !== "development" ? getUploadApiBaseUrl() : getUploadDevelopmentApiBaseUrl(), getCurrentLocation(), getCurrentUser(), getCurrentBooth(), getCurrentCameraId());

  appReloadRecovery(process.env.NODE_ENV, getCurrentCameraId(), getCurrentBooth(), getCurrentUser());
}

function getSignalRServer() {
  return process.env.VUE_APP_SIGNALR_URL;
}
function getDevelopmentSignalRServer() {
  return process.env.VUE_APP_SIGNALR_URL || (/localhost/.test(location.href) ? "https://localhost:5005/sneakermakerws"
                                              : "https://goosewssdev.eu.ngrok.io/sneakermakerws");

}
function getDevelopmentApiBaseUrl(): any {
  return /localhost/.test(location.href) ? "https://localhost:5005/api" 
                                              : "https://goosewssdev.eu.ngrok.io/api";
                                            }
function getUploadDevelopmentApiBaseUrl(): any {
  return /localhost/.test(location.href) ? "https://localhost:5001/api" 
                                              : "https://gooseserverdev.eu.ngrok.io/api";
                                            }
function getApiBaseUrl(): any {
  return process.env.VUE_APP_API_URL;
}

function getUploadApiBaseUrl(): string {
  return process.env.VUE_APP_UPLOAD_API_URL;
}
function getCurrentLocation(): string {
  const token = localStorage.getItem('sales-booth-auth');
  if (token) {
    try {
      const jwt = jwtDecode(token);
      const boothLocation = jwt['booth-location-description'];
      if (boothLocation) {
        return boothLocation;
      }
    } catch (e) {
      // nothing
    }
  }
  return 'UNKNOWN';
}

function getCurrentBooth(): string {
  const token = localStorage.getItem('sales-booth-auth');
  if (token) {
    try {
      const jwt = jwtDecode(token);
      const boothLocation = jwt['booth'];
      if (boothLocation) {
        return boothLocation;
      }
    } catch (e) {
      // nothing
    }
  }
  return 'UNKNOWN';
}

function getCurrentUser(): string {
  const authToken = localStorage.getItem('sales-booth-auth');
  if (!authToken) return null;
  const jwt = jwtDecode(authToken);
  if (jwt) {
    return jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
  }
  return null;
}

function getCurrentCameraId(): number {
  const authToken = localStorage.getItem('sales-booth-auth');
  if (!authToken) return -1;
  const jwt = jwtDecode(authToken);
  if (jwt) {
    return +(jwt["cameraId"] || 1111);
  }
  return 0;
}
