










import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class CustomAlert extends Vue {
  @Prop() title: string;
  @Prop() body: string;
  @Prop({ default: false }) showButton: boolean;
  shown: boolean = false;
  onHide: () => void;

  public show(container?: Element, onHide?: () => void) {
    this.shown = true;
    this.onHide = onHide;
    if (container) {
      this.$mount();
      container.appendChild(this.$el);
    }
  }

  public hide() {
    this.shown = false;
    if(this.onHide){
      this.onHide();
    }
  }
}
