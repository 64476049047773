
































































































































































































































































import BaseVue from "../utilities/base-vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ControlPanel from "../components/ControlPanel.vue";
import SellerButtons from "../components/SellerButtons.vue";
import CustomOptions from "../components/CustomOptions.vue";
import CustomButton from "../components/CustomButton.vue";
import AudioInput from "../components/AudioInput.vue";
import AudioOutput from "../components/AudioOutput.vue";
import RtcStatsPanel from "../components/rtc-stats-panel.vue";
import Modal from "../components/Modal.vue";
import CustomSelect from "../components/CustomSelect.vue";
import CustomConfirm from "../components/CustomConfirm.vue";
import CustomCountdown from "../components/CustomCountdown.vue";
import CustomAlert from "../components/CustomAlert.vue";
import Countdown from "../components/Countdown.vue";
import VideoZoom from "../components/VideoZoom.vue";
import ScreenSaver from "../components/ScreenSaver.vue";
import { IAgoraRTCRemoteUser, IRemoteAudioTrack, IRemoteVideoTrack, UID } from "agora-rtc-sdk-ng";
import { VideoStartedEventArgs } from "@/agora-rtc";
import { CallMode } from "../utilities/call-mode";
import { allCameras, CameraType, isCamera } from "../utilities/camera-type";
import * as Sentry from "@sentry/vue";
import AgoraRecordingApi, { AgoraRecordingInfo, StartAgoraRecordingOptions } from "@/services/agora-recordings-apis";
import { createUUID } from "@/utilities/uid-utils";
import PolaroidButton from "@/components/polaroid/PolaroidButton.vue";
import OnlineExperienceSetup from "@/components/OnlineExperienceSetup.vue";
import NetworkCheck from "@/components/network-check/NetworkCheck.vue";
import BluetoothCheck from "@/components/bluetooth-check/bluetooth-check.vue";
import { OnlineSession } from "@/models/online-session";
import fetchJson from "@/utilities/fetch-json";
import Semaphore from "@/utilities/semaphore";
@Component({
  name: "Seller",
  components: {
    SellerButtons,
    CustomOptions,
    ControlPanel,
    CustomButton,
    RtcStatsPanel,
    Modal,
    CustomSelect,
    CustomConfirm,
    AudioInput,
    AudioOutput,
    VideoZoom,
    CustomAlert,
    Countdown,
    CustomCountdown,
    ScreenSaver,
    OnlineExperienceSetup,
    PolaroidButton,
    NetworkCheck,
    BluetoothCheck,
  },
  props: {
    // booth: {
    //   type: String,
    //   default: "milano1"
    // }
  },
  data() {
    return {};
  },
})
export default class Seller extends BaseVue {
  experienceMode: "InStore" | "Online" | null = null;
  cameraStream: MediaStream;
  recordingSession: string;
  recordingsFiles: string[];
  locale: string = this.$route.params.locale || "en";
  experienceStarting: boolean = false;
  forceRecordingCameraReset: number;

  agoraRecordingApi: AgoraRecordingApi = new AgoraRecordingApi();
  agoraRecordingInfo: AgoraRecordingInfo;
  changeCameraTimeout: number;

  pausedCamera: number = null;
  countdownTiming: number = (parseInt(process.env.VUE_APP_COUNTDOWN_MINUTES) || 5) * 60;
  alertTiming: number = (parseInt(process.env.VUE_APP_ALERT_TIMEOUT_MINUTES) || 15) * 60;
  channelSuffix: string;
  customerVideoActive: boolean = false;
  onlineSession: OnlineSession = null;
  onlineSessionStartedAt: Date;

  private _isOnlineDisabled: boolean = undefined;
  polaroidRecordingTimestamp: number;

  public get isOnlineDisabled(): boolean {
    return this._isOnlineDisabled !== undefined ? this._isOnlineDisabled : this.getIfOnlineIsDisabled();
  }

  public set isOnlineDisabled(v: boolean) {}

  getIfOnlineIsDisabled(): boolean {
    const daSet = process.env.VUE_APP_ONLINE_DISALLOWED_IN || "[]";
    const disallowedIn: string[] = JSON.parse(daSet);
    this._isOnlineDisabled = disallowedIn.some((din) => this.booth === din);
    return this._isOnlineDisabled;
  }

  startExperienceSemaphore: Semaphore<void> = new Semaphore<void>(1);

  public async setExperienceMode(requestedMode: "InStore" | "Online" | null) {
    await this.startExperienceSemaphore.callFunction(async () => {
      if (this.experienceMode != null) {
        console.log("Experience already started, returning");
        return;
      }

      console.log("Starting experience " + requestedMode);
      this.experienceStarting = true;
      switch (requestedMode) {
        case "InStore":
          await this.startInStoreExperience();
          break;
        case "Online":
          if (!(await this.setupOnlineExperience())) {
            this.experienceStarting = false;
            return;
          }
          break;
      }
      this.experienceStarting = false;
      this.experienceMode = requestedMode;
    });
  }

  public get onlineSessionCustomerName(): string {
    return this.onlineSession ? this.onlineSession.name : null;
  }

  public get polaroidEnabled(): boolean {
    return this.customerVideoActive && this.activeCamera === 0;
  }

  public get localVideoElement(): HTMLVideoElement {
    return document.querySelector("#local_stream video") as HTMLVideoElement;
  }

  public get isInStoreExperience(): boolean {
    return this.experienceMode === "InStore";
  }

  public get isOnlineExperience(): boolean {
    return this.experienceMode === "Online";
  }

  public get onRecording() {
    return this.experienceMode == "InStore" && this.activeCamera >= 0;
  }

  public get onPausing() {
    return this.experienceMode == "InStore" && this.activeCamera === -2;
  }

  public get hasIncomingVideo(): boolean {
    if (this.experienceMode !== "Online") return false;
    return this.customerVideoActive || this.activeCamera !== 0;
  }

  backToHome() {
    this.experienceMode = null;
  }

  async onNewBluetoothDeviceAccepted() {
    if (this.experienceMode == "Online") {
      await this.unpublishTracks();
      await this.leaveBoothChannel();
      this.localVideoTrack.getMediaStreamTrack().stop();
      this.localAudioTrack.getMediaStreamTrack().stop();
    }
    this.$signalr.connection.stop();
    this.$jsBridge.requestReloadForNewBluetoothDevice();
  }

  async startInStoreExperience() {
    const constraints = {
      audio: true,
      video: {
        facingMode: "user",
        width: { min: 1278, ideal: 1278 },
        height: { min: 720, ideal: 720 },
        frameRate: { min: 15, ideal: 30 },
        aspectRatio: { ideal: 1.7777778 },
      },
    };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const videoContainer: HTMLDivElement = document.createElement("div");
    videoContainer.style.width = "100%";
    videoContainer.style.height = "100%";
    videoContainer.style.position = "relative";
    videoContainer.style.overflow = "hidden";
    videoContainer.style.backgroundColor = "black";

    const videoElement: HTMLVideoElement = document.createElement("video");
    videoElement.muted = true;
    videoElement.volume = 0;
    videoElement.setAttribute("autoplay", "");
    videoElement.setAttribute("playsinline", "");
    videoElement.style.width = "100%";
    videoElement.style.height = "100%";
    videoElement.style.position = "absolute";
    videoElement.style.left = "0px";
    videoElement.style.top = "0px";
    videoElement.style.transform = "rotateY(180deg)";
    videoElement.style.objectFit = "cover";

    videoElement.style.transform = "scaleX(-1)";

    document.querySelector("#local_stream").appendChild(videoElement);
    videoElement.srcObject = stream;

    await new Promise<void>((resolve) => {
      videoElement.oncanplay = () => resolve();
    });

    this.cameraStream = stream;
    this.recordingsFiles = [];
    let recordingSession: string = createUUID();
    let timestamp: number = Number(new Date());
    this.$logSend.addToContext("session", recordingSession);
    if (process.env.VUE_APP_FULL_SESSION_RECORDING_ENABLED == "true") {
      let { recordingSession, timestamp } = await this.$instorerecording.startRecordingSession(stream, this.uid);
      this.startforceRecordingCameraReset(CameraType.SNEAKER_MAKER);
      console.log("Started recording new session " + recordingSession);
      this.recordingSession = recordingSession;
      this.addRecordingFile(recordingSession, timestamp, 1111);
    } else {
      this.recordingSession = recordingSession;
    }
    await this.$signalr.sendChangeCamera(0, "InStore", recordingSession, timestamp);
  }

  async onPauseCamera() {
    this.pausedCamera = this.activeCamera;
    await this.onChangeCamera(-2);
    (this.$refs.customPause as CustomAlert).show();
  }

  async onResumeCamera() {
    await this.onChangeCamera(this.pausedCamera);
    (this.$refs.customPause as CustomAlert).hide();
    this.pausedCamera = null;
  }

  async stopInStoreExperience() {
    this.activeMenu = false;
    (this.$refs.customPause as CustomAlert).shown = false;
    if (await (this.$refs.customConfirm as CustomConfirm).show()) {
      if (process.env.VUE_APP_FULL_SESSION_RECORDING_ENABLED == "true") {
        await this.$instorerecording.stopRecordingSession();
        await this.$instorerecording.markSessionTimings();
      }
      await this.onChangeCamera(-1);

      const video = document.querySelector("#local_stream video") as HTMLVideoElement;
      video.pause();
      video.srcObject = null;
      video.remove();

      this.cameraStream.getVideoTracks().forEach((v) => v.stop());
      this.cameraStream.getAudioTracks().forEach((v) => v.stop());

      localStorage.setItem(`recordingFiles_${this.recordingSession}`, JSON.stringify(this.recordingsFiles));
      this.$router.push({
        name: "nfc-reader",
        params: {
          session: this.recordingSession,
          locale: this.locale,
          mode: "InStore",
        },
      });
    } else if (this.pausedCamera !== null) {
      (this.$refs.customPause as CustomAlert).show();
    }
  }

  async setupOnlineExperience() {
    const onlineSetupComp = this.$refs.onlineSetup as OnlineExperienceSetup;
    if (!onlineSetupComp) {
      console.error("Online setup component not found in page cannot start setupping online experience");
      alert("An error occurred while trying to setup online experience, contact support");
      return false;
    }
    const setupDialogResult = await onlineSetupComp.show();
    if (setupDialogResult == "dialogCancelled") {
      return false;
    }

    this.onlineSession = setupDialogResult;
    if (this.onlineSession && !this.onlineSession.isClosed) {
      await this.startOnlineExperience(this.onlineSession.customerUrl, this.onlineSession.recordingSessionId);
      window.setTimeout(async () => await this.sendCallStats(this.onlineSessionStartedAt, null, this.channel, this.uid, "AfterCallStart"), 30000);
      return true;
    }
    return false;
  }

  async startOnlineExperience(channelName: string, recordingSessionId: string) {
    await this.$signalr.sendChangeCamera(0);
    await this.getCameraTrack(null);
    this.addPosterAttribute(
      "local_stream",
      "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Crect stroke-width='0' id='svg_1' height='100' width='100' y='0' x='0' stroke='%23000' fill='%23000000'/%3E%3C/g%3E%3C/svg%3E"
    );
    this.addPosterAttribute(
      "customer_stream",
      "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Crect stroke-width='0' id='svg_1' height='100' width='100' y='0' x='0' stroke='%23000' fill='%23000000'/%3E%3C/g%3E%3C/svg%3E"
    );
    await this.playVideoTrackTo("local_stream");
    this.channelSuffix = channelName;
    await this.joinCall();
    this.setCurrentCall(channelName);
    const tracks = [this.localVideoTrack.getMediaStreamTrack(), this.localAudioTrack.getMediaStreamTrack()];
    this.cameraStream = new MediaStream(tracks);
    this.ensureRecordingSessionForOnlineExperience(true, recordingSessionId);
    this.activeCamera = 0;
    // await this.startAgoraRecording(this.activeCamera);
    return true;
  }

  ensureRecordingSessionForOnlineExperience(forceRecreate?: boolean, proposedValue?: string) {
    if (!this.recordingSession || forceRecreate) {
      this.recordingSession = proposedValue || createUUID();
      this.$logSend.addToContext("session", this.recordingSession);
    }
  }

  public camera1isInRtcChannel = false;
  public camera2isInRtcChannel = false;

  public camera1active = false;
  public camera2active = false;
  public sharingScreen = false;
  public activeCamera = 0;
  public activeMenu = false;
  public activeSettings = false;
  public modalOpen = false;
  public assistantActive = false;
  public settingsPanelMode = "audio";
  public idleStatus = null;
  statsEnabled = false;
  public $route: any;
  protected me: Seller;
  private uid = 1111;
  public coords = null;
  public outfitVideo: HTMLVideoElement = null;
  mode = CallMode.OffCall;
  @Prop({ default: "milano1" }) booth: string;
  interfaceIsVisible = true;
  currentCallSlug: string;
  callUrl: string = "";
  customerVideo: any = null;

  constructor() {
    super();
    const vm = this;
    this.$logSend.addToContext("user", this.$gooseapi.getUsername());
    this.$logSend.addToContext("uid", this.uid);
    this.$logSend.addToContext("booth", this.booth);

    this.$agoraClientScreen.addEventListener("screen-share-ended", function () {
      vm.onChangeCamera(0);
    });

    this.$agoraClient.addEventListener("video-started", this.onVideoStarted);

    document.addEventListener("visibilitychange", () => {
      document.visibilityState == "hidden" ? vm.onTabHidden() : vm.onTabVisible();
    });

    this.$once("customer-entered-call", () => {
      this.onCustomerEnteredCall();
    });
  }

  async onPolaroidPictureTaken() {
    if (process.env.VUE_APP_THREE_SECONDS_VIDEO_GADGET_ENABLED != "true") return;
    const { recordingSession, timestamp } = await this.$instorerecording.startRecordingSession(
      this.cameraStream,
      1111,
      this.recordingSession,
      this.polaroidRecordingTimestamp,
      true
    );
    this.recordingsFiles = [];
    this.addRecordingFile(recordingSession, timestamp, 1111);

    this.polaroidRecordingTimestamp = timestamp;
    await new Promise<void>((resolve) => {
      setTimeout(async () => {
        await this.$instorerecording.stopRecordingSession();
        resolve();
      }, 3000);
    });
  }

  async onCustomerEnteredCall() {
    if (!this.agoraRecordingInfo) {
      this.ensureRecordingSessionForOnlineExperience();
      await this.startAgoraRecording(this.activeCamera);
      this.onlineSessionStartedAt = new Date();
    }
  }

  onCameraStillPublishing(caller: Seller, cameraId: number, booth: string) {
    this.resolvePublishingCamera(cameraId);
  }

  onCameraRecording(caller: Seller, cameraId: number, recordingSession: string, timestamp: number) {
    this.resolveRecordingCamera(cameraId);
    this.addRecordingFile(recordingSession, timestamp, cameraId);
  }

  async onTabVisible() {
    // const latestCamera = +sessionStorage.getItem("current-active-camera");
    // if (latestCamera !== this.activeCamera) {
    //   // const { success, videoTrack } =
    //   await this.onChangeCamera(latestCamera, true, true);
    //   sessionStorage.removeItem("current-active-camera");
    // }
  }
  async onTabHidden() {
    // if (allCameras.includes(this.activeCamera)) {
    //   sessionStorage.setItem("current-active-camera", this.activeCamera.toString());
    //   this.activeCamera = -1;
    // }
  }

  async beforeDestroy() {
    this.clearRecordingCameraResetTimeout();
  }

  clearRecordingCameraResetTimeout() {
    console.log(`clearing recording camera reset timeout`);
    if (this.forceRecordingCameraReset) clearTimeout(this.forceRecordingCameraReset);
    this.forceRecordingCameraReset = null;
  }

  async mounted() {
    this.$logSend.removeFromContext("session");
    Sentry.setContext("device", {
      location: localStorage.getItem("SM-provisioning-location"),
    });

    this.$signalr.onJoinedBoothControl(this, (...args) => {
      this.onJoinedBoothControl(this, ...args);
    });

    this.$signalr.onCameraRecording(this, this.onCameraRecording);
    this.$signalr.onCameraStillPublishing(this, this.onCameraStillPublishing);

    this.$signalr.onLeftBoothControl(this, (...args) => {
      this.onLeftBoothControl(this, ...args);
    });

    const { occupied, message } = await this.$gooseapi.occupyBooth(this.booth, "seller");
    if (!occupied) {
      alert(message);
      this.$router.push("/");
      return;
    }

    await this.tryJoinBoothControlOrReload();
  }

  async tryJoinBoothControlOrReload() {
    const pleaseWait = new CustomAlert({
      propsData: {
        title: 'Please wait',
        body: 'Initializing Sneakers Maker Control Station',
        showButton: false
      }
    });
    pleaseWait.show(this.$refs.container as Element);
    let retries = 0;
    do {
      try {
        await this.joinBoothControl(this.uid, this.booth, "seller");
        break;
      } catch (e) {
        console.warn(e);
        await new Promise<void>(resolve =>
          setTimeout(() => {
            resolve();
          }, Math.pow(3, ++retries) * 1000)
        );
        // alert("There was an error initializing the page, please refresh to retry");
      }
    } while (retries < 3);
    pleaseWait.hide();
    if (retries >= 3) {
      const customAlert = new CustomAlert({
        propsData: {
          title: "Error",
          body: "There was an error initializing the page, click ok to retry",
          showButton: true
        }
      });
      customAlert.show(this.$refs.container as Element, () => {
        location.reload();
      });
      
    }
  }

  public get isInCall(): boolean {
    return this.mode == CallMode.InCall;
  }

  toggleMenu(value) {
    this[value] = this[value] ? false : true;
  }

  startEvent(e) {
    if (e.target.id === "assistantButton") {
      this.assistantActive = true;
      console.log("start");
    }
  }

  stopEvent() {
    if (this.assistantActive) {
      console.log("stop");
      this.assistantActive = false;
    }
  }

  audioInputSelected(device: MediaDeviceInfo = null) {
    this.setAudioInput(device);
  }

  audioOutputSelected(device: MediaDeviceInfo = null) {
    this.setAudioOutput(device);
  }

  onVideoStarted(e: CustomEvent<VideoStartedEventArgs>) {
    const data = e.detail;
    // if (data.userId === 10002 && data.videoElement.style.objectFit === "contain") {
    //   this.outfitVideo = data.videoElement;
    //   data.videoElement.addEventListener("touchstart", this.getVideoCoords);
    //   data.videoElement.addEventListener("mousedown", this.getVideoCoords);
    // }
    this.$emit("camera-video-started");
  }

  getVideoCoords(e: MouseEvent | TouchEvent) {
    console.log(e);
    const normalizedEvent = e instanceof TouchEvent ? e.targetTouches[0] : e;
    const targetY = normalizedEvent.clientY;
    const targetX = normalizedEvent.clientX;
    this.coords = {
      x: targetX,
      y: targetY,
    };
    e.preventDefault();
  }

  async joinCall() {
    try {
      await Promise.all([await this.joinBoothChannel(this.uid, this.channelSuffix)]);
      if (this.isInCall) return;
      console.log("getting candidate audio device");
      let candidateAudio = await this.getLastAudioDevice();
      console.log(`got candidate audio device with deviceId ${candidateAudio.deviceId}`);
      let audioTrack = await this.getAudioTrack(candidateAudio);
      if (!audioTrack) {
        const devices = await this.$agoraClient.getDevices();
        const onlyAudio = devices.filter((d) => d.kind === "audioinput" && d.deviceId !== "default");
        if (onlyAudio && onlyAudio.length > 0) {
          candidateAudio = onlyAudio[0];
          audioTrack = await this.getAudioTrack(candidateAudio);
          if (!audioTrack) {
            audioTrack = await this.getAudioTrack(true);
          }
        }
      }
      if (!this.localVideoTrack) {
        this.localVideoTrack = await this.getCameraTrack(null);
      }
      await this.publishTracks([this.localVideoTrack, audioTrack]);

      this.closeModal();
      this.mode = CallMode.InCall;
    } catch (error) {
      await this.$agoraClientScreen.leave();
      await this.leaveBoothChannel();
      console.error(error);
      alert("Could not join this call, an error occurred, please check your connection and try again.");
    }
  }

  async getLastAudioDevice(): Promise<MediaDeviceInfo> {
    const devices = await this.$agoraClient.getDevices();
    const onlyAudio = devices.filter((d) => d.kind === "audioinput" && d.deviceId !== "default");
    console.log(`all audio input devices: ${JSON.stringify(onlyAudio, null, 2)}`);
    if (onlyAudio && onlyAudio.length > 0) return onlyAudio[onlyAudio.length - 1];
    return null;
  }

  async joinScreenSharing() {
    const screenToken = await this.getAgoraToken(this.channel, 4444);
    await this.$agoraClientScreen.join(this.channel, 4444, screenToken);
  }

  async leaveCall() {
    await this.unpublishTracks();
    await this.leaveBoothChannel();
    this.mode = CallMode.OffCall;
  }

  async closeCall(force?: boolean) {
    if (!this.isInCall && !force) return;
    if (await (this.$refs.customConfirm as CustomConfirm).show()) {
      try {
        await this.stopAgoraRecording();
        await this.$signalr.sendChangeCamera(-1);
        const onlineSessionEndedAt = new Date();
        await this.$dbService.setSessionTimings(this.recordingSession, this.onlineSessionStartedAt, onlineSessionEndedAt);
        this.sendCallStats(this.onlineSessionStartedAt, onlineSessionEndedAt, this.channel, this.uid, "AfterCallEnd");
        if (this.currentCallSlug) {
          try {
            await fetchJson<OnlineSession>(`${process.env.VUE_APP_UPLOAD_API_URL ?? "https://localhost:5001/api"}/online-sessions/code/${this.onlineSession.sneakerMakerCode}`, {
              method: "PATCH",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                isClosed: true,
              }),
            });
            await this.$signalr.connection.send("CloseCall", this.channelSuffix, this.booth);
          } catch (error) {
            console.error(error);
            if (!confirm(`Could not propertly close this call: an error occurred.\nDo you wish to continue closing?`)) {
              return;
            }
          }
        }
        if (this.isInCall) {
          await this.unpublishTracks();
          this.localVideoTrack.getMediaStreamTrack().stop();
          this.localAudioTrack.getMediaStreamTrack().stop();
        }
        this.channelSuffix = null;
        this.setCurrentCall(null);
        this.customerEnteredCall = false;
        this.mode = CallMode.OffCall;

        localStorage.setItem(`recordingFiles_${this.recordingSession}`, JSON.stringify(this.recordingsFiles));
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 250));
      } catch (error) {
        console.error(error);
        alert(`Could not propertly close this call, check your internet connection and retry`);
        return;
      }
      this.experienceMode = null;
      this.$logSend.disable();
      await this.$router.push({
        name: "nfc-reader",
        params: {
          session: this.recordingSession,
          locale: this.locale,
          mode: "Online",
        },
      });
    }
  }

  async stopAgoraRecording(recordingInfo?: AgoraRecordingInfo) {
    if (process.env.VUE_APP_FULL_SESSION_RECORDING_ENABLED != "true") return;

    if (recordingInfo || this.agoraRecordingInfo) {
      await this.agoraRecordingApi.stopRecording(recordingInfo || this.agoraRecordingInfo);
      if (!recordingInfo) this.agoraRecordingInfo = null;
    }
  }

  async logout(e: MouseEvent, silently: boolean = false, freeBooth: boolean = true) {
    if (freeBooth) {
      const { occupied, message } = await this.$gooseapi.freeBooth(this.booth);
      if (occupied && !silently) {
        alert(message);
        return;
      }
    }
    localStorage.removeItem("sales-booth-auth");
    this.setCurrentCall(null);
    this.$router.push({
      name: "Home",
    });
    if (!silently) {
      alert("You have logged out");
    }
  }

  async onRemoteUserJoined(sender, user: IAgoraRTCRemoteUser) {
    const uid = +user.uid;
    if (uid === CameraType.DRAW_ON_SNEAKERS) {
      this.camera1isInRtcChannel = true;
    }
    if (uid === CameraType.PUNCHING_MACHINE) {
      this.camera2isInRtcChannel = true;
    }
    sender.addRemoteUser(uid, user);
  }

  async onRemoteUserLeft(sender, user: IAgoraRTCRemoteUser) {
    if (user.uid === 3333) {
      // // this.onChangeCamera(0);
      // this.interfaceIsVisible = false;
      // return;
    }
    if (user.uid === 10001) {
      this.camera1isInRtcChannel = false;
    }
    if (user.uid === 10002) {
      this.camera2isInRtcChannel = false;
    }
    if (this.activeCamera === user.uid && !this.changingCamera) this.onChangeCamera(0);
    sender.removeRemoteUser(user.uid);
  }

  async onRemoteUserPublished(sender: Seller, user: IAgoraRTCRemoteUser) {
    if (user.uid === 3333) {
      await sender.$agoraClient.subscribe(user, true, true, "customer_stream");
      if (user.hasAudio) {
        this.remoteAudioTracks.set(user.audioTrack.getTrackId(), user.audioTrack);
      }
      if (user.hasVideo) {
        debugger;
        this.emitCustomerEnteredCall();
        const video = await this.findElementBySelector("#customer_stream video");
        await new Promise<void>((resolve) => {
          video.addEventListener(
            "progress",
            () => {
              resolve();
            },
            {
              // once: true
            }
          );
        });
        this.customerVideo = video;
        this.interfaceIsVisible = true;
        this.customerVideoActive = true;
      }
    }
  }

  customerEnteredCall = false;
  emitCustomerEnteredCall() {
    debugger;
    if (!this.customerEnteredCall) {
      this.$emit("customer-entered-call");
    }
  }

  async onRemoteUserUnpublished(sender, user: IAgoraRTCRemoteUser) {
    if (allCameras.includes(+user.uid) && this.activeCamera != 0) {
      this.onChangeCamera(this.activeCamera);
    }
    if (+user.uid === 3333 && !user.hasVideo) {
      this.customerVideoActive = false;
      this.customerVideo = null;
    }
    if (user.audioTrack) {
      this.remoteAudioTracks.delete(user.audioTrack.getTrackId());
    }
  }

  async onJoinedBoothControl(sender: Seller, connectionId: string, booth: string, uid: UID, role: string) {
    console.debug(`User ${uid} joined booth control channel or refreshed join information`);
    if (role === "camera") {
      this.$camerasTracker.addAvailableCamera(uid, connectionId);
    }

    // if (role === "buyer") {
    //   await this.joinCall();
    // }

    // if (role === "seller") {
    //   alert("You have been disconnected by another user");
    //   await this.logout(null, true, false);
    // }
  }

  async onLeftBoothControl(sender: Seller, connectionId: string, booth: string, uid: UID, role: string) {
    console.debug(`User ${uid} left booth control channel`);
    if (role === "camera") {
      this.$camerasTracker.removeAvailableCamera(uid, connectionId);
    }
  }

  async onShareScreen() {
    await this.$agoraClientScreen.publishScreen("camera_stream");
    await this.onChangeCamera(4444, false);
    this.sharingScreen = true;
    this.togglePiP();
  }

  async onScreenShareEnded() {
    this.sharingScreen = false;
    await this.onChangeCamera();
    this.togglePiP();
  }

  changingCamera = false;
  showChangingCamera = false;
  async onChangeCamera(cameraId?: number, doSubscribe: boolean = true, forcePortraitLayout: boolean = false, forceCameraReset: boolean = false) {
    if (this.changingCamera) return;
    if (this.activeCamera === cameraId && !forceCameraReset) {
      return;
    }
    this.clearRecordingCameraResetTimeout();

    let result;
    switch (this.experienceMode) {
      case "InStore":
        result = await this.onChangeCameraInStore(cameraId, forceCameraReset);
        if (cameraId >= 0) this.startforceRecordingCameraReset(cameraId);
        break;
      case "Online":
        result = await this.onChangeCameraOnline(cameraId, doSubscribe, forcePortraitLayout);
        break;
      default:
        return;
    }
    if (cameraId >= 0 && !forceCameraReset) {
      (this.$refs.customCountdown as CustomCountdown)?.resetTimeout();
    }

    return result;
  }

  startforceRecordingCameraReset(cameraType?: CameraType) {
    let timeoutDuration = (cameraType === CameraType.SNEAKER_MAKER ? +process.env.VUE_APP_TABLET_RESET_TIMEOUT_MINUTES : +process.env.VUE_APP_CAMERA_RESET_TIMEOUT_MINUTES) || 45;
    console.log(`Starting new timeout of ${timeoutDuration} minutes for long running recordings`);
    this.forceRecordingCameraReset = window.setTimeout(async () => {
      console.log("Long running recordings timeout elapsed, resetting current recording camera " + this.activeCamera);
      await this.onChangeCamera(this.activeCamera, true, false, true);
    }, timeoutDuration * 60 * 1000);
  }

  async onChangeCameraInStore(cameraId: number, forceCameraReset: boolean = false) {
    console.debug(`onChangeCamera in store event started with cameraId ${cameraId}, forceCameraReset ${forceCameraReset}`);

    console.debug("starting changing camera");
    if (this.forceRecordingCameraReset) clearTimeout(this.forceRecordingCameraReset);
    this.changingCamera = true;
    this.showChangingCamera = cameraId !== this.activeCamera && cameraId >= 0;

    if (this.$instorerecording.recording && cameraId === 0 && forceCameraReset) {
      console.log("Forced camera reset, stopping previous recording");
      await this.$instorerecording.stopRecordingSession();
      console.log("Forced camera reset,  previous recording stopped");
    }

    const changeCameraResult = await this.tryChangeCameraWithTimeout(cameraId, false, false, forceCameraReset);
    console.debug("camera change result: ", changeCameraResult);

    // Step 3: valuto il risultato
    if (!changeCameraResult.success) {
      // Se la camera non è cambiata ed è diversa dal default, ritorno a default
      if (cameraId) {
        await this.doChangeCamera(0);
        this.activeCamera = 0;
        return;
      }
      // Avviso l'utente
      alert("There has been an issue while trying to change camera or share screen, please retry");
    }

    if (changeCameraResult.success && process.env.VUE_APP_FULL_SESSION_RECORDING_ENABLED == "true") {
      if (cameraId == 0 && (!this.$instorerecording.recording || forceCameraReset)) {
        const timestamp = Number(new Date());
        const { recordingSession } = await this.$instorerecording.startRecordingSession(this.cameraStream, this.uid, this.recordingSession, timestamp);
        this.recordingSession = recordingSession;
        this.addRecordingFile(this.recordingSession, timestamp, 1111);
      } else {
        this.$instorerecording.stopRecordingSession();
      }
    }

    if (cameraId !== -1) this.startforceRecordingCameraReset(cameraId);
    this.changingCamera = false;
    this.showChangingCamera = false;
    this.activeCamera = cameraId;
    return changeCameraResult;
  }

  addRecordingFile(recordingSession: string, timestamp: number, cameraId: number | string) {
    if (!this.recordingsFiles) {
      this.recordingsFiles = [];
    }
    this.recordingsFiles.push(`${recordingSession}_${timestamp}_${cameraId}.webm`);
  }

  async onChangeCameraOnline(cameraId: number, doSubscribe: boolean, forcePortraitLayout: boolean) {
    console.debug(`onChangeCamera online event started with cameraId ${cameraId} and doSubscribe ${doSubscribe}`);
    if (this.changingCamera) return;
    if (this.activeCamera === cameraId) return;

    if (cameraId > 0) {
      console.debug("Joining channel if not joined yet...");
      await this.joinBoothChannel(this.uid, this.channelSuffix);
      console.debug("Channel joined.");
    }
    console.debug("starting changing camera");
    this.changingCamera = true;
    this.showChangingCamera = cameraId !== this.activeCamera && cameraId >= 0;

    const changeCameraResult = await this.tryChangeCameraWithTimeout(cameraId, doSubscribe, forcePortraitLayout);

    console.debug("camera change result: ", changeCameraResult);

    // Step 3: valuto il risultato
    if (!changeCameraResult.success) {
      // Se la camera non è cambiata ed è diversa dal default, ritorno a default
      if (cameraId) {
        await this.doChangeCamera(0);
      }
      // Avviso l'utente
      alert("There has been an issue while trying to change camera or share screen, please retry");
    }
    this.changingCamera = false;
    this.showChangingCamera = false;
    const oldRecordingInfo = {
      ...this.agoraRecordingInfo,
    };
    await this.startAgoraRecording(this.activeCamera);
    const stoppingPromise = this.stopAgoraRecording(oldRecordingInfo);
    await stoppingPromise;
    return changeCameraResult;
  }

  async startAgoraRecording(cameraId: number, recordingOptions?: StartAgoraRecordingOptions) {
    if (process.env.VUE_APP_FULL_SESSION_RECORDING_ENABLED != "true") return null;

    let retryOn = true;
    let result: AgoraRecordingInfo;
    const uids: string[] = [];
    const cameraUid = (cameraId === 0 ? "1111" : cameraId).toString();
    uids.push(cameraUid);
    if (this.onlineSession.allowCustomerRecording) uids.push("3333");
    var timestamp = Number(new Date());
    do {
      const startOptions: StartAgoraRecordingOptions = {
        ...recordingOptions,
        channelName: this.channel,
        recordingSession: this.recordingSession,
        uids,
        timestamp,
        maxIdleTime: this.onlineSession.allowCustomerRecording ? 600 : 30,
      };
      result = await this.agoraRecordingApi.startRecording(startOptions).then((ri: AgoraRecordingInfo) => {
        if (ri.sid) this.addRecordingFile(this.recordingSession, timestamp, cameraUid);
        return ri;
      });
      if (!result.sid) {
        retryOn = this.popupFailedRecordingStartConfirm(cameraId);
      }
    } while (retryOn && !result.sid);
    this.agoraRecordingInfo = result.sid ? result : null;
    return result;
  }

  popupFailedRecordingStartConfirm(cameraId: number): boolean {
    const confirmMessage =
      cameraId === 3333
        ? "Recording service failed to start to record customer stream, do you want to retry to start recording?\nIf you coose NO, the experience will continue without recording the customer stream."
        : "Recording service failed to start to record selected camera, do you want to retry to start recording?\nIf you coose NO, the experience will continue without recording.";

    return confirm(confirmMessage);
  }

  tryChangeCameraWithTimeout(
    cameraId: number,
    doSubscribe: boolean,
    forcePortraitLayout: boolean,
    forceCameraReset: boolean = false
  ): Promise<{ success: boolean; videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack }> {
    return new Promise<{ success: boolean; videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack }>((resolve) => {
      // Step 1: imposto timeout; quando scatta, fa un reject della promise su cameraPublishing (vd metodo cameraIsPublishing) e risolve questa Promise tornando false (la camera non è cambiata)
      // fare il clear del timeout precedente? come gestire la rejection della promise senza mostrare errore?
      // if (this.changeCameraTimeout) clearTimeout(this.changeCameraTimeout);
      this.changeCameraTimeout = window.setTimeout(() => {
        console.debug("change camera timedout");
        (this.experienceMode === "InStore" ? this.camerasRecording : this.camerasPublishing).find((cp) => cp.cameraId === cameraId)?.rejecter();
        console.debug("returning false");
        resolve({ success: false });
      }, 15000);

      console.debug("invoking doChangeCamera");

      // Step 2: invoco cambio camera
      this.doChangeCamera(cameraId, doSubscribe, forcePortraitLayout, forceCameraReset)
        // Quando la Promise viene mantenuta
        .then((changeCameraResult) => {
          console.debug("doChangeCamera returned normally with result ", changeCameraResult);

          // Se la camera è cambiata, posso fare il clear del timeout
          if (changeCameraResult.success) {
            clearTimeout(this.changeCameraTimeout);
          }
          // Risolvo la Promise ritornando il risultato, qualunque esso sia
          resolve(changeCameraResult);
        })
        // In caso di errore
        .catch((error) => {
          // Loggo l'errore (che è sempre bene) e risolvo ritornando false
          console.error("doChangeCamera raised an exception", error);
          Sentry.captureException(error);
          clearTimeout(this.changeCameraTimeout);

          resolve({ success: false });
        });
    });
  }

  async doChangeCamera(
    cameraId?: number,
    doSubscribe: boolean = true,
    forcePortraitLayout: boolean = false,
    forceCameraReset: boolean = false
  ): Promise<{ success: boolean; videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack }> {
    let result: { success: boolean; videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack } = { success: false };
    const timestamp = Number(new Date());
    await this.$signalr.sendChangeCamera(
      cameraId,
      this.experienceMode,
      this.experienceMode === "InStore" ? this.$instorerecording.recordingSession : null,
      timestamp,
      forceCameraReset,
      this.channelSuffix
    );
    if (this.sharingScreen) {
      await this.$agoraClientScreen.unpublishScreen();
      this.sharingScreen = false;
      this.togglePiP();
    }
    console.log(`onChangeCamera ${cameraId}`);
    let camera: IAgoraRTCRemoteUser;
    switch (this.experienceMode) {
      case "InStore":
        if (cameraId > 0) {
          await this.cameraIsRecording(cameraId);
        }
        result = { success: true, videoTrack: null, audioTrack: null };
        break;
      case "Online":
        console.log("waiting for camera to being publishing");
        if (allCameras.includes(cameraId)) {
          try {
            camera = await this.cameraIsPublishing(cameraId);
            // if(camera) window.clearTimeout(this.changeCameraTimeout)
            console.debug(`camera ${cameraId} is now publishing`);
          } catch (error) {
            console.debug("Error waiting for camera to be publishing");
            return result;
          }
          if (doSubscribe) {
            console.log("subscribing stream");
            const playVideoTo = "camera_stream";
            const { videoTrack, audioTrack } = await this.$agoraClient.subscribe(camera, true, true, playVideoTo);
            await new Promise<void>((resolve) => {
              this.$on("camera-video-started", () => {
                this.$off("camera-video-started");
                resolve();
              });
            });
            // if (forcePortraitLayout) {
            //   this.$agoraClient.setupVideoAfterFirstFrameDecoded(videoTrack, playVideoTo);
            // }
            result = { success: true, videoTrack, audioTrack };
          }
          break;
        } else if (cameraId === 0) {
          result = { success: true };
        }
    }

    this.activeCamera = cameraId || 0;
    this.activeMenu = false;
    console.debug("doChangeCamera: camera has changed");

    return result;
  }

  public get existsCurrentCall(): boolean {
    return !!this.currentCallSlug;
  }

  async openModal() {
    if (this.modalOpen) return;
    this.activeMenu = false;

    if (this.currentCallSlug) {
      this.modalOpen = true;
      return;
    }
    const createCallResult = await this.$gooseapi.createCall(this.booth, new Date());
    if (createCallResult.success) {
      this.setCurrentCall(createCallResult.slug);
      this.modalOpen = true;
    } else {
      alert(createCallResult.message || "Can't create a new call, an error occurred. Please retry.");
      this.closeModal();
    }
  }

  setCurrentCall(slug: string) {
    this.currentCallSlug = slug;
    if (slug) {
      localStorage.setItem("current-call-slug", this.currentCallSlug);
      this.callUrl = `${location.protocol}//${location.host}`;
      this.callUrl += `/${this.currentCallSlug}`;
    } else {
      localStorage.removeItem("current-call-slug");
      this.callUrl = null;
    }
  }

  closeModal() {
    if (!this.modalOpen) return;
    this.modalOpen = false;
  }

  copyCallUrlToClipboard() {
    if (this.callUrl) navigator.clipboard.writeText(this.callUrl);
    this.closeModal();
  }

  async togglePiP() {
    const theDoc: any = document;
    if ("pictureInPictureEnabled" in document && this.customerVideo) {
      try {
        if (this.sharingScreen && this.customerVideo !== theDoc.pictureInPictureElement) {
          await this.customerVideo.requestPictureInPicture();
        } else if (!this.sharingScreen && this.customerVideo === theDoc.pictureInPictureElement) {
          await theDoc.exitPictureInPicture();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
