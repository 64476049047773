import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Camera from "../views/Camera.vue";
import Seller from "../views/Seller.vue";
import NFCReader from "../views/NFCReader.vue";
import DownloadYourExperience from "../views/DownloadYourExperience.vue";
import Buyer from "../views/Buyer.vue";
import CheckDevice from "../views/CheckDevice.vue";
import Assistant from "../views/Assistant.vue";
import ByePage from "../views/ByePage.vue";
import ErrorPage from "../views/ErrorPage.vue";
import Logs from "../views/Logs.vue";
import jwtDecode, { JwtPayload } from "jwt-decode";
import GooseAPIClient, { getCurrentAuthToken } from "@/services/goose-apis";
import { OnlineSession } from "@/models/online-session";
import fetchJson from "@/utilities/fetch-json";

Vue.use(VueRouter);

class BuyerCallPathGuardFactory extends Vue {
  $gooseapi: GooseAPIClient;
  async guard(to: Route, _, next: NavigationGuardNext) {
    if(!to.params["callSlug"]) {
      next({path: "/c/bye"});
      return;
    }
    try {
      const getCallResult = await fetchJson<OnlineSession>(`${process.env.VUE_APP_UPLOAD_API_URL ?? "https://localhost:5001/api"}/customer-online-sessions/${to.params["callSlug"]}`);
      if (getCallResult && getCallResult.isClosed) {
        next({ path: "/c/bye" });
        return;
      }
      localStorage.setItem("callInfo", JSON.stringify(getCallResult));
      next();
      return;
    } catch (error) {
      alert(error.message || "Cannot proceed, an internal error occurred. Please try again.");
      next({
        name: "error",
        params: { error: error.message || "Cannot proceed, an internal error occurred." }
      });
    }
  }
}

export class UserMustBeLoggedAndInRole {
  role: string;
  constructor(role: string) {
    this.role = role;
  }

  async guard(to: Route, from, next: NavigationGuardNext) {
    if(process.env.VUE_APP_CUSTOMER_DOMAIN === location.host) {
      next({path: '/c/bye'});
      return;
    }

    const tokenStr = getCurrentAuthToken();
    let token: JwtPayload;
    try {
      token = jwtDecode<JwtPayload>(tokenStr);
    } catch (error) {
      console.warn("Could not decode token " + tokenStr);
    }
    if (token?.exp && new Date(token.exp * 1000) > new Date()) {
      if (token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === this.role) {
        next();
        return;
      }
    }
    alert("Please login to enter");
    if (router.currentRoute.name !== "Home") {
      next({
        name: "Home",
        query: { returnUrl: encodeURIComponent(to.path), requiredRole: this.role }
      });
      return;
    }
    next();
  }
}
const routes: RouteConfig[] = [
  {
    path: "/:locale?/camera/:booth?/:cameraId?",
    name: "camera",
    component: Camera,
    props: true,
    beforeEnter: (to, from, next) => new UserMustBeLoggedAndInRole("camera").guard(to, from, next)
  },
  {
    path: "/:locale?/seller/:booth?",
    name: "seller",
    component: Seller,
    props: true,
    beforeEnter: (to, from, next) => new UserMustBeLoggedAndInRole("seller").guard(to, from, next)
  },
  {
    path: '/:locale?/get-your-experience/:session/:hash',
    name: 'DownloadYourExperience',
    component: DownloadYourExperience,
    props: true
  },
  {
    path: "/:locale?/nfc-reader/:booth/:session/:mode",
    name: "nfc-reader",
    component: NFCReader,
    props: true,
    beforeEnter: (to, from, next) => new UserMustBeLoggedAndInRole("seller").guard(to, from, next)
  },
  {
    path: "/buyer/:booth?",
    name: "buyer",
    component: Buyer,
    props: true
  },
  {
    path: "/assistant/:booth?",
    name: "assistant",
    component: Assistant,
    props: true,
    beforeEnter: (to, from, next) => new UserMustBeLoggedAndInRole("assistant").guard(to, from, next)
  },
  {
    path: "/logs/:booth?",
    name: "logs",
    component: Logs,
    props: true
  },
  {
    path: "/c/bye/:callSlug?",
    name: "bye",
    component: ByePage,
    props: true
  },
  {
    path: "/check-my-device",
    name: "checkmydevice",
    component: CheckDevice
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage
  },
  {
    path: "/c/:callSlug?",
    name: "Call",
    component: Buyer,
    beforeEnter: (to, from, next) => new BuyerCallPathGuardFactory().guard(to, from, next)
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if(process.env.VUE_APP_CUSTOMER_DOMAIN === location.host) {
        next({path: '/c/bye'});
        return;
      }
      const tokenStr = localStorage.getItem('sales-booth-auth');
      if (!tokenStr) {
        next({});
        return;
      }
      let token: JwtPayload;
      try {
        token = jwtDecode<JwtPayload>(tokenStr);
      } catch (error) {
        console.warn("Could not decode token " + tokenStr);
      }
      if (token?.exp && new Date(token.exp * 1000) > new Date()) {
        const role = token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        try {
          next({
            name: role,
            params: {
              booth: token["booth"],
              locale: token["booth-locale"],
              cameraId: token["cameraId"]
            }
          });
        } catch (error) {
          //          
        }
        return;
      }

      next();
    }
  },
  {
    path: '*',
    component: ErrorPage,
    props: {
      error: 'Not found'
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
