



import { start } from "@/services/file.worker";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
@Component({})
export default class GenericCountdown extends Vue {
  private value: number = 0;
  @Prop({default: 0}) startValue: number;
  timer: number;

  @Watch("startValue")
  onStartValueChanged(newValue: number) {
    this.value = newValue;
    this.resetTimer();
  }

  resetTimer() {
    if (this.timer) window.clearInterval(this.timer);
    if(this.startValue <= 0) return;
    this.value = this.startValue;
    this.timer = window.setInterval(() => this.tick(), 1000);
  }

  tick() {
    if (--this.value <= 0) {
      this.elapsed();
    }
  }

  elapsed(): void {
    window.clearInterval(this.timer);
    this.$emit("elapsed");
  }

  mounted() {
     this.resetTimer();
  }
}
