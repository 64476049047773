export class OnlineSession {
   id: number;
   name: string;
   customerUrl: string;
   sneakerMakerCode: string;
   allowCustomerRecording: boolean;
   isClosed: string;
   boothSlug: string;
   customerAgoraToken: string;
   createdAtUtc: Date;
   updatedAtUtc: Date;
   recordingSessionId: string;
 }
 