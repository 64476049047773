







import Vue from "vue";
import Component from "vue-class-component";
import CustomButton from "@/components/CustomButton.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import { Prop, Watch } from "vue-property-decorator";
import { ICameraVideoTrack, ILocalVideoTrack } from "agora-rtc-sdk-ng";
import { start } from "@/services/file.worker";
@Component({
  components: {
    CustomButton,
    CustomAlert
  }
})
export default class SwitchCamera extends Vue {
  switching: boolean = false;
  _currentIndex = 0;
  devices: MediaDeviceInfo[];
  switchError: string = null;
  @Prop() videoTrack: ICameraVideoTrack | ILocalVideoTrack;
  @Prop({ type: String, required: false}) theme: string;
  @Prop({type: Number, default: 1}) camerasCount: number;

  get hasError() {
    return !!this.switchError;
  }

  get switchTheme() {
    return this.theme ? `circle-off ${this.theme}`: 'circle-off';
  }

  public get currentIndex(): number {
    return this._currentIndex;
  }

  public set currentIndex(v: number) {
    this._currentIndex = v;
  }

  constructor() {
    super();
  }

  async mounted() {
    this.devices = await this.$agoraClient.getCameras();
    this.$root.$off("camera-switched");
    this.$root.$on("camera-switched", () => {
      this.switching = false;
    });
  }

  public get hasTrack(): boolean {
    return !this.switching;
  }

  async switchCamera() {
    const startIndex = this._currentIndex;
    if (!(await this.trySwitchTheEasyWay())) {
      this._currentIndex = startIndex;
      await this.trySwitchTheHardWay();
    } else {
      this.switching = false;
    }
  }
  
  async trySwitchTheEasyWay(): Promise<boolean> {
    if (!("setDevice" in this.videoTrack)) return false;
    this.switching = true;
    if (++this._currentIndex >= this.devices.length) {
      this._currentIndex = 0;
    }
    const newDevice = this.devices[this._currentIndex];
    return await this.videoTrack
      .setDevice(newDevice.deviceId)
      .then(() => true)
      .catch(reason => {
        console.warn(`Can't switch camera the easy way because of ${reason}`);
        return false;
      });
  }

  async trySwitchTheHardWay() {
    this.switching = true;
    const startIndex = this._currentIndex;
    let newLocalTrack: ILocalVideoTrack;
    let error: any = null;
    do {
      if (++this._currentIndex >= this.devices.length) {
        this._currentIndex = 0;
      }
      if (this._currentIndex === startIndex) break;
      try {
        newLocalTrack = await this.$agoraClient.createVideoTrack(this.devices[this._currentIndex]);
      } catch (e) {
        error = e;
      }
    } while (!newLocalTrack && this._currentIndex != startIndex);
    if (newLocalTrack) {
      this._currentIndex = this.devices.findIndex(d => d.deviceId === newLocalTrack.getMediaStreamTrack().getCapabilities().deviceId);
      this.$emit("switchingCamera", this.devices[this._currentIndex].deviceId);
    } else {
      if (error.name === "AgoraRTCException") {
        this.switchError = `Could not switch camera, please check your camera and microphone permissions`;
      } else {
        this.switchError = `Could not switch camera, an error occurred`;
      }
      (this.$refs.alert as any).show();
      this.switching = false;
    }
  }
}
